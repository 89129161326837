import { forwardRef, useEffect } from 'react'
import './main-item.css'

export const MainItem = forwardRef((props, ref) => {

    const index = props.index;
    const title = props.title;
    const icon = props.icon;
    const color = props.color;
    const url = props.url;

    useEffect(() => {
        const parent = document.getElementById('main-item-root' + index);
        var delay = index * 80;
        parent.style.animationDelay = delay + "ms";
        parent.style.animationName = "slideInFromBottom";
        parent.style.animationDuration = "450ms";
        parent.onanimationend = () => {
            parent.style.opacity = "1";
        }

        const txtTitle = document.getElementById('main-item-title' + index);
        txtTitle.innerHTML = title;
    });

    function onClickItem() {
        props.onClickMenuItem();
        setTimeout(function () {
            //window.open(url, '_self');
            window.location.href = url;
        }, 1500);

    }

    return (
        <div id={"main-item-root" + index}
            className="main-item-root"
            style={{ backgroundColor: color }}
            onClick={onClickItem}>
            <div className='main-item-parent'>
                <img className='main-item-icon' src={icon} />
                <h3 id={"main-item-title" + index} className='main-item-title'></h3>
            </div>
        </div>
    )
});