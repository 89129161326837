import './banner.css';
import { images } from '../../data/images';
import { videos } from '../../data/videos';
import { icons } from '../../data/images';
import { forwardRef, useEffect } from 'react';

export const Banner = forwardRef((props, ref) => {

    var root;

    useEffect(() => {
        const key = "showedBanner";
        const showedBanner = sessionStorage.getItem(key);
        if (showedBanner == null) {
            sessionStorage.setItem(key, false);
        }
        if (showedBanner == "false") {
            setTimeout(() => {
                root = document.getElementById('banner-root');
                root.style.display = "flex";
            }, 1500);
    
            setTimeout(() => {
                const parent = document.getElementById('banner-parent');
                parent.style.display = "block";
            }, 1900);
            sessionStorage.setItem(key, true);
        }
    });

    function onVideoEnd() {
        onClickClose();
    }

    function onClickClose() {
        root.style.animationName = "bannerOverlayClose";
        root.style.animationDuration = "500ms";
        root.onanimationend = () => {
            root.style.display = "none";
        }
        const video = document.getElementById('video');
        if (video != null) {
            video.pause();
        }
    }

    function toggleMute() {
        /*const video = document.getElementById('video');
        if (video.muted === true) {
            video.muted = false;
        }
        else if (video.muted === false) {
            video.muted = true;
        }*/
    }

    return (
        <div id='banner-root' className='banner-root'>
            <div id='banner-parent' className='banner-parent'>
                <video
                    id='video' autoPlay="autoplay" muted controls
                    className='banner-video' onEnded={onVideoEnd}
                >
                    <source src={videos.banner} type="video/mp4" />
                </video>
                { /*
                <img src={images.banner} className='banner-img' />
                */}
                <img src={icons.close} className='banner-close' onClick={onClickClose} />
            </div>
        </div>
    )

});