import './css/App.css';
import './../src/ui/loading/loading.css';
import { Background } from './ui/background/background';
import { Main } from './ui/main/main';
import { Banner } from './ui/banner/banner';

function App() {

  function onClickMenuItem() {
    const loading = document.getElementById('loading-root');
    loading.style.display = "flex";
  }

  return (
    <div className="App">
      <Main onClickMenuItem={onClickMenuItem} />
      <Background />
      <Banner />
      <div id='loading-root' className='loading-root'>
        <h2>Sedang memuat...</h2>
        <div className='loading-parent'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default App;
