import './footer.css';

export const Footer = () => {
    return (
        <div className='footer-root'>
            <div></div>
            {/* <a><b>Copyright</b> PT BAS</a> */}
        </div>

    )
}