import { useEffect } from 'react';
import './background.css';
import { images } from '../../data/images';

export const Background = () => {

    useEffect(() => {
        const top = document.getElementById('gradient-top');
        top.onanimationend = () => {
            top.style.scale = "1"
        }

        const right = document.getElementById('gradient-right');
        right.onanimationend = () => {
            right.style.scale = "1"
        }

        const bgLine = document.getElementById('bg-line');
        bgLine.style.backgroundImage = "url("+images.line+")";
        bgLine.onanimationend = () => {
            bgLine.style.display = "block";
            bgLine.style.opacity = "0.3";
        }

        setTimeout(() => {
            bgLine.style.animationName = "lineShow";
            bgLine.style.animationDuration = "2.5s";
            bgLine.style.animationDelay = "300ms";
        }, 300);
    });

    return (
        <div className="bg-root">
            <div id='gradient-top' className='gradient-top' />
            <div id='gradient-right' className='gradient-right' />
            <div id='bg-line' className='bg-line' />
        </div>
    )
}