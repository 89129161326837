import './header.css';
import { images } from '../../data/images';

export const Header = () => {
    return(
        <div className='header-root'>
            <img className='header-logo' src={images.logo}/>
            <img className='header-logo' src={images.logoBapenda}/>
        </div>
    )
}