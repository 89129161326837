// Icons
const dirIcon = "./icons/";
// Image
const dirImage = "./img/";

export const icons = {
    // Menu
    airTanah: dirIcon+"air-tanah.png",
    bphtb: dirIcon+"bphtb.png",
    bumi: dirIcon+"bumi.png",
    eSppt: dirIcon+"e-sppt.png",
    hiburan: dirIcon+"hiburan.png",
    hotel: dirIcon+"hotel.png",
    jalan: dirIcon+"jalan.png",
    mineral: dirIcon+"mineral.png",
    parkir: dirIcon+"parkir.png",
    pembayaran: dirIcon+"pembayaran.png",
    pendataan: dirIcon+"pendataan.png",
    pengaduan: dirIcon+"pengaduan.png",
    reklame: dirIcon+"reklame.png",
    restoran: dirIcon+"restoran.png",
    simoni: dirIcon+"simoni.png",
    close: dirIcon+"close.png"
}

export const images = {
    logo: dirImage+"logo.png",
    logoBapenda: dirImage+"logo-bapenda.png",
    banner: dirImage+"banner.png",
    line: dirImage+"line.webp"
}