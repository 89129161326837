import { icons } from "./images";

export const MenuData = [
    {
        id: 1,
        title: "Pajak Bumi &<br />Bangunan",
        icon: icons.bumi,
        color: getColor("Red"),
        url: "https://esppt.id/simpbb/login"
    },
    {
        id: 2,
        title: "Cek<br />PBB",
        icon: icons.eSppt,
        color: getColor("Purple"),
        url: "https://esppt.id/simpbb/login"
    },
    {
        id: 3,
        title: "BPHTB",
        icon: icons.bphtb,
        color: getColor("Green"),
        url: "https://bphtb-bapenda.malangkab.go.id/login"
    },
    {
        id: getColor(),
        title: "PBJT <br /> Jasa Perhotelan",
        icon: icons.hotel,
        color: getColor("Blue"),
        url: "https://sipanji.id/pdrd/Auth"
    },
    {
        id: 5,
        title: "PBJT<br />Makanan dan/atau Minuman",
        icon: icons.restoran,
        color: getColor("Orange"),
        url: "https://sipanji.id/pdrd/Auth"
    },
    {
        id: 6,
        title: "PBJT<br />Jasa Kesenian dan Hiburan",
        icon: icons.hiburan,
        color: getColor("Blue"),
        url: "https://sipanji.id/pdrd/Auth"
    },
    {
        id: 7,
        title: "Pajak<br />Reklame",
        icon: icons.reklame,
        color: getColor("Orange"),
        url: "https://sipanji.id/pdrd/Auth"
    },
    {
        id: 8,
        title: "PBJT<br /> Tenaga Listrik",
        icon: icons.jalan,
        color: getColor("Red"),
        url: "https://sipanji.id/pdrd/Auth"
    },
    {
        id: 9,
        title: "Pajak Mineral<br />Bukan Logam<br />& Batuan",
        icon: icons.mineral,
        color: getColor("Purple"),
        url: "https://sipanji.id/pdrd/Auth"
    },
    {
        id: 10,
        title: "Pajak<br />Air Tanah",
        icon: icons.airTanah,
        color: getColor("Green"),
        url: "https://sipanji.id/pdrd/Auth"
    },
    {
        id: 11,
        title: "PBJT<br/> Jasa Parkir",
        icon: icons.parkir,
        color: getColor("Purple"),
        url: "https://sipanji.id/pdrd/Auth"
    },
    {
        id: 12,
        title: "Pendataan<br />Objek Pajak",
        icon: icons.pendataan,
        color: getColor("Green"),
        url: "https://sipanji.id/pendataan/Auth.html"
    },
    {
        id: 13,
        title: "Pengaduan<br />Pajak",
        icon: icons.pengaduan,
        color: getColor("Blue"),
        url: "https://sipanji.id/pdrd/Auth/cs_wa"
    },
    {
        id: 14,
        title: "SIMONI",
        icon: icons.simoni,
        color: getColor("Orange"),
        url: "https://sipanji.id/etax/auth.html"
    },
    {
        id: 15,
        title: "Tata Cara<br />Pembayaran",
        icon: icons.pembayaran,
        color: getColor("Red"),
        url: "https://sipanji.id/pdrd/Auth/help_pay/bri"
    }
];

function getColor(color) {
    const properties = "--color"+color;
    return getComputedStyle(document.body).getPropertyValue(properties);
}