import './main.css';
import { MainItem } from './item/main-item';
import { MainItem2 } from './item2/main-item2';
import { MenuData } from '../../data/menu-data';
import { Header } from '../header/header';
import { Footer } from '../footer/footer';
import { forwardRef, useEffect } from 'react';

export const Main = forwardRef((props, ref) => {

    useEffect(() => {
        window.onpageshow = function (event) {
            if (event.persisted) {
              window.location.reload();
            }
          };
    })
    
    const Items = () => {
        return MenuData.map((item, i) => {
            return (
                <MainItem key={i} index={i}
                    title={item.title}
                    icon={item.icon}
                    color={item.color}
                    url={item.url}
                    onClickMenuItem={onClickMenuItem}/>
            );
        });
    }

    function onClickMenuItem() {
        props.onClickMenuItem();
    }

    return (
        <div className='main-root'>
            <div className='main-parent'>
                <div></div>
                <Header />
                <div id='main-menu-container' className='main-menu-container'>
                    <Items  />
                </div>
                <Footer />
                <div></div>
            </div>
        </div>
    )
})